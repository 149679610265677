<template>
  <!-- eslint-disable -->
  <v-main app class="md-8">
    <v-container fluid class="">
      <v-row justify="center">
        <v-col cols="12">
          <router-view :key="$route.path" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// Utilities
/* eslint-disable */
import { get } from "vuex-pathify";

export default {
  name: "PageView",
  data: () => ({
    srcs: {
      "/": "",
      "/error/": ""
    }
  }),

  computed: {
    path: get("route/path"),
    src() {
      return this.srcs[this.path] || "";
    }
  }
};
</script>
